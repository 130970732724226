import api     from '@/services/api';
import router  from '@/router';
import { H } from 'highlight.run';

export default {

	async createAccount ({ commit, dispatch }, { profile }) {
		const user = await api.auth.createAccount({
			fields: [
				'profile',
				'token'
			],
			profile
		});
		if (!user ||
				!user.token) {
			return;
		}
		commit('setProfile', {
			profile: user.profile
		});
		commit('setToken', {
			token: user.token
		});
		router.push('/browse-courses');

		dispatch('trackUser');
	},

	async updateTimezone ({ commit, getters }) {
		const currentTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Europe/London';
		const usersTimezone = getters.getUsersTimezone;
		console.log('currentTimezone', currentTimezone);
		console.log('usersTimezone', usersTimezone);
		if (currentTimezone === usersTimezone) {
			console.log('No need to update timezone');
			return;
		}
		console.log('Updating timezone');
		const profile = await api.auth.updateCurrentUserProfile({
			profile: {
				timezone: currentTimezone
			}
		});
		console.log(profile);
		if (!profile) {
			console.log('No profile, return');
			return;
		}
		commit('setProfile', {
			profile
		});
	},

	async logIn ({ commit, dispatch }, { credentials }) {
		const user = await api.auth.logIn({
			fields: [
				'profile',
				'token'
			],
			credentials
		});
		if (!user ||
				!user.token) {
			return;
		}
		commit('setProfile', {
			profile: user.profile
		});
		commit('setToken', {
			token: user.token
		});
		await dispatch('updateTimezone');
		router.push('/browse-courses');

		dispatch('trackUser');
	},

	async logInWithCode ({ commit, dispatch }, { code }) {
		if (!code) {
			return;
		}
		const verification = await api.auth.verifyPasswordResetCode({
			fields: [
				'token'
			],
			code
		});
		if (!verification ||
				!verification.token) {
			return false;
		}
		commit('setProfile', {
			profile: verification.profile
		});
		commit('setToken', {
			token: verification.token
		});

		dispatch('trackUser');
	},

	async setToken ({ commit }, { token }) {
		commit('setToken', {
			token: token
		});
	},

	async clearRefreshProgress ({ commit }) {
		commit('setTokenRefreshInProgress', false);
	},

	async refreshToken ({ commit }) {

		commit('setTokenRefreshInProgress', true);

		const user = await api.auth.refreshAuth().catch(err => {
			commit('setTokenRefreshInProgress', false);
			throw err;
		});
		commit('setTokenRefreshInProgress', false);

		if (!user ||
				!user.token) {
			return;
		}
		commit('setProfile', {
			profile: user.profile
		});
		commit('setToken', {
			token: user.token
		});
	},

	async fetchUserProfile ({ commit }) {
		const profile = await api.auth.getCurrentUserProfile();

		if (!profile) {
			return;
		}
		commit('setProfile', {
			profile
		});
	},

	async restartVerification ({ commit }) {
		// API CALL to start email verify
		const userProfile = await api.auth.verifyEmail();

		// set updated profile from response
		if (userProfile) {
			commit('setProfile', {
				profile: userProfile
			});
		}

	},

	async logOut ({ commit }) {
		await api.auth.logOut();
		commit('logOut');
		commit('clearProfile');
		router.push('/');
	},

	trackUser ({ getters }) {
		const profile = getters.getProfile;
		if (profile) {
			H.identify(profile.email, profile);
		}
	}
};
