import store   from '@/store';
import router  from '@/router';

export const includeToken = [
	(config) => {
		if (!store.getters['auth/getToken']) {
			return config;
		}
		// include bearer token as header
		const token = store.getters['auth/getToken'];
		config.headers.common.Authorization = `Bearer ${token}`;

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
];

export const checkTokenExpiry = [
	(config) => {

		const refreshAfter = store.getters['auth/getTokenRefreshAfter'];

		// if the token is due to expire, refresh it
		if (refreshAfter && config.url !== '/auth/refresh') {
			const refreshInProgress = store.getters['auth/getRefreshIsInProgress'];
			const refreshDue = refreshAfter < new Date();

			if (refreshDue && !refreshInProgress) {
				// console.log(`Token refresh due after ${refreshAfter.toISOString()}`);
				store.dispatch('auth/refreshToken');
			}

			if (!refreshDue) {
				store.dispatch('auth/clearRefreshProgress');
			}
		}

		return config;
	},
	(error) => {
		Promise.reject(error);
	}];

export const checkTokenIsValid = [
	(config) => {
		return config;
	},
	(error) => {
		// if token exists but has expired, log user out
		// and push to login page
		if (!error.config ||
				!error.response) {
			return Promise.reject(error);
		}
		if (error.response.status !== 401) {
			return Promise.reject(error);
		}
		if (!store.getters['auth/getToken']) {
			return Promise.reject(error);
		}
		// TODO: update the token example
		// https://github.com/axios/axios/issues/934#issuecomment-322003342
		// return updateToken().then((token) => {
		//   error.config.headers.xxxx <= set the token
		//   return axios.request(config);
		// });
		const originalRequest = error.config;
		// return if this is already a retried request
		if (originalRequest._retry) {
			return Promise.reject(error);
		}
		originalRequest._retry = true;
		store.dispatch('auth/logOut');
		router.push('/login');
		return Promise.reject(error);
	}
];
