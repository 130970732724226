import { camelCase } from 'change-case';
import { decode } from 'jsonwebtoken';

const REFRESH_TOKEN_BEFORE_DAYS = process.env.VUE_APP_REFRESH_TOKEN_BEFORE_DAYS;
const REFRESH_TOKENS_EARLY = (process.env.VUE_APP_REFRESH_TOKENS_EARLY === '1');

export default {
	setToken (state, { token }) {
		state.token = token;
		const decoded = decode(token);

		// when there is an expiry date, record it along with the date after which it should be refreshed
		if (decoded.exp && REFRESH_TOKENS_EARLY) {
			state.tokenExp = new Date(decoded.exp * 1000);

			// a token should be refreshed N days before it expires
			const days = REFRESH_TOKEN_BEFORE_DAYS || 7;
			// the date after which the token should be refreshed
			const refreshAfter = new Date(state.tokenExp);
			refreshAfter.setDate(refreshAfter.getDate() - days);

			state.refreshAfter = refreshAfter;
		} else {
			state.tokenExp = null;
			state.refreshAfter = null;
			state.tokenRefreshInProgress = false;
		}

	},

	setTokenRefreshInProgress (state, value) {
		state.tokenRefreshInProgress = value;
	},

	logOut (state) {
		state.user = null;
		state.token = null;
		state.tokenExp = null;
		state.refreshAfter = null;
		state.tokenRefreshInProgress = false;
	},

	setProfile (state, { profile }) {
		const formattedProfile = {};
		Object.keys(profile).forEach((key) => {
			formattedProfile[camelCase(key)] = profile[key];
		});
		state.profile = {
			...state.profile,
			...formattedProfile
		};
	},

	clearProfile (state) {
		state.profile = {};
	}

};
