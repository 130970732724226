export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const arrayShift = (arr, shiftCount) => {
	const result = [];
	if (arr.length === 1 || shiftCount === 0) {
		return arr;
	}
	for (let index = 0; index < arr.length; index++) {
		result[(index + shiftCount) % arr.length] = arr[index];
	}
	return result;
};

export const sleep = (delay) => new Promise(resolve => setTimeout(resolve, delay));

export const randomNumber = (min, max) => {
	const r = Math.random() * (max - min) + min;
	return Math.floor(r);
};

export const chunk = (arr, size) =>
	Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
		arr.slice(i * size, i * size + size)
	);

export const createSuitElement = (symbol, colour) => {
	const outerNode = document.createElement('span');
	const innerNode = document.createElement('span');
	innerNode.innerHTML = symbol;
	outerNode.setAttribute('data-colour', colour);
	outerNode.setAttribute('data-element', 'suit-symbol');
	outerNode.innerHTML = innerNode.outerHTML;
	return outerNode.outerHTML;
};
