import Vue                   from 'vue';
import Vuex                  from 'vuex';
import createMultiTabState   from 'vuex-multi-tab-state';
import ui                    from './ui/';
import auth                  from './auth/';
import admin                 from './admin/';
import browseCourses         from './browseCourses/';
import lesson                from './lesson/';
import simulator             from './simulator/';
import simulatorControls     from './simulatorControls/';
import quiz                  from './quiz/';
import dealLibrary           from './dealLibrary/';
import screenRecorder        from './screenRecorder/';
import simulatorParameters   from './simulatorParameters/';
// import createPersistedState  from 'vuex-persistedstate';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

Vue.config.devtools = debug;

export default new Vuex.Store({
	modules: {
		ui,
		auth,
		admin,
		browseCourses,
		lesson,
		simulator,
		simulatorControls,
		quiz,
		dealLibrary,
		screenRecorder,
		simulatorParameters
	},
	plugins: [
		// createPersistedState({}),
		createMultiTabState(({
			key: 'bridge128',
			statesPaths: [
				'auth.token',
				'auth.tokenExp',
				'auth.refreshAfter',
				'auth.tokenRefreshInProgress',
				'auth.profile',
				// TODO: Do we really need to share these values between multiple tabs?
				//  Let's disable it until we find a better way
				//  to implement remote control for the simulator
				//  because it causes additional bugs.
				// 'simulator.currentGameData',
				// 'simulator.gameStates',
				// 'simulator.currentGameStateIndex',
				// 'simulator.hiddenCards',
				// 'simulator.highlightingModeIsEnabled',
				// 'simulator.highlightedCards',
				// 'simulator.rolesAreVisible',
				// 'simulator.DDSScoresAreVisible',
				// 'simulator.faceDownCards',
				// 'simulator.tablePositions',
				// 'simulatorControls.remoteControlWindowIsOpen',
				// 'dealLibrary.groups',
				// 'dealLibrary.currentPage',
				// 'dealLibrary.numPages',
				// 'dealLibrary.totalItems',
				// 'dealLibrary.searchString',
				// 'dealLibrary.activeFilters',
				// TODO: ...
				'ui.seenTourSteps'
			]
		}))
	],
	strict: debug
	// strict: false
});
