import { get }      from '@/services/api/methods';
import { post }     from '@/services/api/methods';
import { put }      from '@/services/api/methods';
import { del }      from '@/services/api/methods';

export default {

	getGroups: ({ include, searchString, filters, page, per, sortBy, sortDir }) => {
		return get('/deals/grouped-deals', {
			options: {
				include,
				searchString,
				filters,
				page,
				per,
				sortBy: 'created_date',
				sortDir: 'desc'
			}
		});
	},

	getGroupById: ({ groupId }) => {
		return get(`/deals/groups/${groupId}`);
	},

	updateGroupById: ({ groupId, group }) => {
		return put(`/deals/groups/${groupId}`, {
			requestData: group
		});
	},

	deleteGroupById: ({ groupId }) => {
		return del(`/deals/groups/${groupId}`);
	},

	getDeal: ({ dealId }) => {
		return get(`/deals/${dealId}`);
	},

	deleteDeal: ({ dealId }) => {
		return del(`/deals/${dealId}`);
	},

	deleteAllDeals: () => {
		return del('/deals/all');
	},

	updateDeal: ({ dealId, deal }) => {
		return put(`/deals/${dealId}`, {
			requestData: deal
		});
	},

	importDealsFromPBN: ({ file, autogroup, config }) => {
		return post('/deals/import-direct', {
			options: {
				autogroup,
				...config
			},
			requestData: file,
			isFormData: true
		});
	},

	queueImportDealsFromPBN: ({ file, autogroup, config }) => {
		return post('/deals/import', {
			options: {
				autogroup,
				...config
			},
			requestData: file,
			isFormData: true
		});
	},

	checkPending: () => {
		return get('/deals/import/pending');
	},

	getTags: () => {
		return get('/deals/tags');
	},

	getScores: ({ dealId, dealIds, lessonId }) => {
		return get('/deals/scores', {
			options: {
				dealId,
				dealIds,
				lessonId
			}
		});
	},

	// deal media

	getDealMedia: ({ dealId }) => {
		return get(`/deals/${dealId}/media`);
	},

	createMediaByDealId: ({ dealId, media }) => {
		return post(`/deals/${dealId}/media`, {
			requestData: media,
			isFormData: true
		});
	},

	getDealMediaById: ({ dealId, mediaId }) => {
		return get(`/deals/${dealId}/media/${mediaId}`);
	},

	updateMediaByDealIdAndMediaId: ({ dealId, mediaId, media }) => {
		return put(`/deals/${dealId}/media/${mediaId}`, {
			requestData: media
		});
	},

	deleteMediaByDealIdAndMediaId: ({ dealId, mediaId }) => {
		return del(`/deals/${dealId}/media/${mediaId}`, {
		});
	},

	// deal result messages

	getDealResultMessages: ({ dealId }) => {
		return get(`/deals/${dealId}/result-messages`);
	},

	createDealResultMessage: ({ dealId, message }) => {
		return post(`/deals/${dealId}/result-messages`, {
			requestData: message
		});
	},

	updateDealResultMessage: ({ messageId, message }) => {
		return put(`/deals/result-messages/${messageId}`, {
			requestData: message
		});
	},

	deleteDealResultMessage: ({ messageId }) => {
		return del(`/deals/result-messages/${messageId}`);
	},

	// deal predefined cards

	getDealPredefinedCards: ({ dealId }) => {
		return get(`/deals/${dealId}/predefined-cards`);
	},

	updateDealPredefinedCards: ({ dealId, cards }) => {
		return put(`/deals/${dealId}/predefined-cards`, {
			requestData: cards
		});
	},

	getPreviousAndNextDeals: ({
		dealId,
		context,
		lessonId
	}) => {
		return get(`/deals/${dealId}/prev-next`, {
			options: {
				context,
				lessonId
			}
		});
	}

};
